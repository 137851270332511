<template>
  <NuxtLayout />
</template>

<script setup>
  const runtimeConfig = useRuntimeConfig();

  useHead({
    link: [
      {
        id: 'brand-styles',
        rel: 'stylesheet',
        href: `/styles/${runtimeConfig.public.SHOP.BRAND_STYLING}-cdn-styles.css`
      }
    ]
  });
</script>

<style lang="scss">
  main {
    order: 1;
    max-width: 100%;
    padding: 30px 30px 300px;

    @include min-screen('medium') {
      order: unset;
      padding: 30px 60px 60px;
      grid-area: content;
    }
  }
</style>

<style>
@import '@ponbike/mixed-storyblok-components/dist/focus/focus.css';
@import '@ponbike/mixed-storyblok-components/dist/focus/mixed-storyblok-components.css';

#CybotCookiebotDialog {
  display: none!important;
}
</style>
